<template>
  <div class="customer_page">
    <!-- 头部 -->
      <div class="banner">
        <common-header style="position:absolute" :navActive="4" />
      </div>
      <div class="icon_show">
        <el-breadcrumb style="font-size: 16px;" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/customer' }">客户案例</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="icon_page" ref="page">
          <div class="icon_page_single_one" v-for="items in caseListO" :key="items.id">
            <div class="icon_page_single_one_img">
              <img :src= items.src>
              <div class="icon_page_single_back">
                <p class="icon_page_single_back_line1">——</p>
                <p class="icon_page_single_back_p">{{items.title}}</p>
                <p class="icon_page_single_back_line2">——</p>
              </div>
            </div>
            <p class="icon_page_single_one_box">{{items.text}}</p>
            <div class="icon_page_single_one_next">
              <span>MORE +</span>
              <span style="color: #333333">→</span>
            </div>
          </div>
        </div>
        <div class="icon_page" ref="iconPage">
           <div class="icon_page_single_one" v-for="items in caseListT" :key="items.id">
            <div class="icon_page_single_one_img">
              <img :src= items.src>
              <div class="icon_page_single_back">
                <p class="icon_page_single_back_line1">——</p>
                <p class="icon_page_single_back_p">{{items.title}}</p>
                <p class="icon_page_single_back_line2">——</p>
              </div>
            </div>
            <p class="icon_page_single_one_box">{{items.text}}</p>
            <div class="icon_page_single_one_next">
              <span>MORE +</span>
              <span style="color: #333333">→</span>
            </div>
          </div>
        </div>
      </div>
    <!-- 路由出口 -->
    <!-- 底部 -->
    <common-footer />
  </div>
</template>
<script>
// 头部公共导航栏
import CommonHeader from '@/components/head'
// 底部公共导航栏
import CommonFooter from '@/components/footer/foot_Index.vue'

export default {
  name: 'customerIndex',
  components: {
    CommonHeader,
    CommonFooter
  },
  data () {
    return {
      caseListO: [
        {
          title: '爱尚阅读管理系统',
          info: '爱尚阅读管理系统',
          src: require('@/assets/img/customer/case_1.jpg'),
          text: '爱尚阅读管理系统',
          id: 1
        },
        {
          title: '市北建投供应链管理系统',
          info: '市北建投供应链管理系统',
          src: require('@/assets/img/customer/case_3.jpg'),
          text: '市北建投供应链管理系统',
          id: 2
        },
        {
          title: '海晟融资租赁业务管理系统',
          info: '海晟融资租赁业务管理系统',
          src: require('@/assets/img/customer/case_5.jpg'),
          text: '海晟融资租赁业务管理系统',
          id: 3
        }
      ],
      caseListT: [
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const __currentScroll = window.pageYOffset
      // 此处数值越大，那么触发事件就越早
      if (__currentScroll >= this.$refs.iconPage.offsetTop - 1000) {
        if (this.$refs.iconPage.className === 'icon_page') {
          this.$refs.iconPage.className = this.$refs.iconPage.className + ' animated fadeInUpBig'
          this.$refs.page.className = this.$refs.iconPage.className + ' animated fadeInDownBig'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.customer_page{
  overflow: hidden;
  width: 100%;
  min-width: 1400px;
}
.banner {
    height: 500px;
    background: url('~@/assets/img/customer/inner-banner.jpg') no-repeat center;
    background-size: 100% 100%;
}
.icon_show {
  overflow: hidden;
  width: 1400px;
  padding-top: 39px;
  margin: auto;
  .icon_title {
    width: 113px;
    height: 16px;
    font-size: 16px;
    letter-spacing: 0px;
    color: #333333;
  }
  .icon_page {
    display: flex;
    justify-content: space-between;
    .icon_page_single_one {
      margin-top: 40px;
      height: 460px;
      position: relative;
      overflow: hidden;
      border: solid 1px #f1f1f1;
      // .icon_page_single_bom {
      //   position: absolute;
      //   width: 680px;
      //   height: 50px;
      //   line-height: 50px;
      //   bottom: 0px;
      //   background-color: rgba(5, 26, 83,0.6);
      //   color: #ffffff;
      //   font-family: MicrosoftYaHei;
      // }
      .icon_page_single_one_img{
        width: 440px;
        height: 330px;
        overflow: hidden;
        position: relative;
        img{
          height: 100%;
        }
        .icon_page_single_back {
          display: none;
          background-color: rgba(5, 26, 83,0.6);
          position: absolute;
          top: 0px;
          left: 0;
          bottom: 100px;
          height: 330px;
          width: 100%;
          .icon_page_single_back_line1{
            width: 20px;
            height: 2px;
            color: #ffffff;
            margin: 0 auto;
            margin-top: 120px;
          }
          .icon_page_single_back_p {
            text-align: center;
            font-size: 22px;
            letter-spacing: 2px;
            color: #ffffff;
            margin-top: 25px;
          }
          .icon_page_single_back_line2{
            width: 20px;
            height: 2px;
            color: #ffffff;
            margin: 0 auto;
            margin-top: 10px;
          }
        }
      }
      .icon_page_single_one_box{
        line-height: 80px;
        width: 400px;
        margin: 0 auto;
        border-bottom: solid 1px #f1f1f1;
        font-size: 20px;
      }
      .icon_page_single_one_next{
        width: 400px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        line-height: 50px;
        color: #999999;
      }
    }
    .icon_page_single_one_img:hover{
      img {
        transition: all .5s ease-in;
      }
      // .icon_page_single_bom {
      //   display: none;
      // }
      .icon_page_single_back {
        display: block;
        animation: iconbacktop 0.5s linear;
      }
    }
  }
}
@keyframes iconbacktop{
  0% {top: 385px;}
  20% {top: 308px;}
  40% {top: 231px;}
  60% {top: 154px;}
  80% {top: 77px}
  100% {top: 0}
}
</style>
